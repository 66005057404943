<template>
  <div class="purchases">
    <div class="sec1 pb-5">
      <div class="container pb-4">
        <div class="row px-5 px-md-0 ">
          <div class="col-xxl-6 col-lg-7  py-lg-0 pb-4">
            <h2 class="black">
              <span class="red">Оформим</span> покупки в турецких магазинах
              и <span class="red">отправим </span> по вашему адресу
            </h2>
          </div>
          <div class="col-xxl-6 col-lg-5">
            <div class="d-flex justify-content-center align-items-center h-100">
            <router-link :to="{name: 'FormPage', params:{formType: 'order'}}" class="btn">Сделать заказ товара <i><img src=img/arrow-forward-24-px.svg alt=""></i></router-link>
            </div>
            </div>
        </div>
      </div>
    </div>
    <div class="sec2 py-4">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-4">
            <img src="img/2-purchases.png" alt="">
          </div>
          <div class="col-md-8 p-4 d-flex justify-content-center align-items-center">
 <div class="text-md-start text-center px-lg-5 lg-md-5">
   <h3 class="black">
     <span class="underline">Делайте покупки</span> <br>
     в интернет-магазинах <br> Турции
   </h3>
   <p>Выбирайте локальные турецкие марки или известные мировые бренды - Zara, H&M, Levi’s, Nike, Apple, Samsung
     и многие другие.
     <span class="d-block pt-3">
                Наши сотрудники закажут нужный товар, заберут из магазина и отправят в Россию или по адресу в Турции
              </span>
   </p>
 </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sec3 py-5">
      <div class="container">
        <h3 class="black text-center">Покупка  в три шага</h3>
        <div class="row d-none d-lg-flex">
          <div class="col-4 ">
            <div class="s3_services_el s3_services_el1 my-3">
              <div class="icon"><img src="img/3-icon5.png" alt=""></div>
              <h4 class="black pt-5">Присылаете
                нам ссылку <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
              <p>на выбранный товар с указанием необходимых параметров: количество, размер, цвет и т.п</p>
            </div>
          </div>
          <div class="col-4 ">
            <div class="s3_services_el s3_services_el2 my-3">
              <div class="icon"><img src="img/3-icon6.png" alt=""></div>
              <h4 class="black pt-5">Оплачиваете покупку <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
              <p>на нашем сайте любой российской банковской картой</p>
            </div>
          </div>
          <div class="col-4 ">
            <div class="s3_services_el s3_services_el3 my-3">
              <div class="icon"><img src="img/3-icon7.png" alt=""></div>
              <h4 class="black pt-5">Получаете товар  <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
              <p>в России через службу доставки или по почте, по вашему адресу
                в Турции  </p>
            </div>
          </div>
        </div>
        <div class="d-block d-lg-none">
          <div  class="sliderPurchases">
            <div>
              <div class="s3_services_el s3_services_el1 my-3">
                <div class="icon"><img src="img/3-icon5.png" alt=""></div>
                <h4 class="black pt-5">Присылаете
                  нам ссылку <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
                <p>на выбранный товар с указанием необходимых параметров: количество, размер, цвет и т.п</p>
              </div>
            </div>
            <sdiv >
              <div class="s3_services_el s3_services_el2 my-3">
                <div class="icon"><img src="img/3-icon6.png" alt=""></div>
                <h4 class="black pt-5">Оплачиваете покупку <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
                <p>на нашем сайте любой российской банковской картой</p>
              </div>
            </sdiv>
            <div>
              <div class="s3_services_el s3_services_el3 my-3">
                <div class="icon"><img src="img/3-icon7.png" alt=""></div>
                <h4 class="black pt-5">Получаете товар  <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
                <p>в России через службу доставки или по почте, по вашему адресу
                  в Турции  </p>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mt-5 d-flex justify-content-center align-items-center">
          <router-link :to="{name: 'FormPage', params:{formType: 'order'}}" class="btn">Заказать товар <i><img src=img/arrow-forward-24-px.svg alt=""></i></router-link>
        </div>
      </div>
    </div>
    <div class="sec4 py-4">
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <div class="d-flex justify-content-center align-items-center px-5">
              <img src="img/4-bg2.png" alt="">
            </div>
          </div>
          <div class="col-md-7 d-flex justify-content-center align-items-center text-md-start text-center">
            <div class="p-4">
              <h3 class="black">Мы тщательно <br> проверим товар</h3>
              <p>Учтем все скидки и спецпредложения. <br>
                Оформим возврат, если вещь не подошла</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sec5 py-5">
      <div class="container">
        <h3 class="text-center black">
          Тарифы
        </h3>
        <div class="d-flex justify-content-center flex-wrap mt-5">
          <div class="rate_el m-3">
            <h4 class="black">Фиксированный</h4>
            <div class="s5_rate_el d-flex flex-column justify-content-between mt-4">
              <p>При покупках до 2 000  TRY</p>
              <h2 class="black mb-5 mt-0">140 TRY
                <span class="fw-normal d-block mt-2" style="font-size: 20px">или <b>500 RUB</b></span></h2>
              <router-link :to="{name: 'FormPage', params:{formType: 'order'}}" class="btn">Сделать заказ <i><img src=img/arrow-forward-24-px.svg alt=""></i></router-link>
            </div>
          </div>
          <div class="rate_el m-3">
<h4 class="black">Оптимальный</h4>
            <div class="s5_rate_el d-flex flex-column justify-content-between mt-4">
      <p>При покупках свыше 2 000  TRY</p>
              <h2 class="black mb-5 mt-0">7 %

                <span class="d-block mt-2" style="font-size: 20px">от суммы заказа</span>
              </h2>

              <router-link :to="{name: 'FormPage', params:{formType: 'order'}}" class="btn">Сделать заказ <i><img src=img/arrow-forward-24-px.svg alt=""></i></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "Purchases",

  mounted(){
    document.querySelector('html').scrollIntoView({
      behavior: 'smooth'
    });
    // eslint-disable-next-line no-undef
    $('.sliderPurchases').slick({
      dots: true,
      arrows: false,
      autoplay: true,
      infinite: true,
    });
  },
  beforeUnmount() {
    //eslint-disable-next-line no-undef
    $('.sliderPurchases').slick('unslick');

  }
}
</script>

<style>

</style>
